import { INPUT_TYPE } from "deskera-ui-library";
import TableDataParser from "../Helper/TableDataParser";
import {
  IColumn,
  IColumnDataSource,
  IFilterCondition,
  ITableFilter
} from "../model/Table";
import { store } from "../redux/store";
import { fetchTableByName } from "../redux/slices/tableSlice";
import { FILTER_LOGICAL_OPERATORS } from "../constants/Enum";
import Utility, { getRandomAlphaNumericString } from "../utility/Utility";
import {
  LOOKUP_FIELD_ENABLE_COL_TYPE,
  TICKET_COLUMNS
} from "../constants/Constant";
import Table from "../services/table";

export const TABLES = {
  CONTACT: "contact",
  DEAL: "deal",
  SEGMENT: "segment",
  CAMPAIGN: "campaign",
  FORM: "form",
  LANDING_PAGE: "landing_page",
  PIPELINE: "pipeline",
  STAGE: "stage",
  ACTIVITY: "activity",
  ACCOUNT: "account",
  AUTOMATION: "automation",
  FUNNEL: "funnel",
  ORDER: "order",
  PRODUCT: "product",
  IMPORT_LOG: `import_log`,
  CHAT_MESSAGE: "chat_message",
  AUTOMATION_LOG: "automation_log",
  BOOKS_QUOTE: "books_quote",
  BOOKS_CONTACT: "books_contact",
  BOOKS_PRODUCT: "books_product",
  AUDIT_LOG: "audit_log",
  TICKET: "ticket",
  QUOTE_SETTINGS: "quote",
  PERMISSION: "permission",
  OVERDUE_ACTIVITY: "overdueActivity",
  SHOP_LEAD: "shop_lead",
  BOOKS_INVOICE: "books_invoice",
  DRAFT: "draft",
  TEMPLATE: "template",
  LEAD_SCORE: "lead_score",
  BOOKS_PRICE_LIST: "books_price_list",
  BOOKS_PRICE_LIST_LOG: "books_price_list_log",
  CURRENCY: "books_currency"
};
export const COLUMN_CODE = {
  CONTACT: {
    NAME: `name`,
    EMAIL: `email`,
    TYPE: `type`,
    PHONE: `phone`,
    ORGANISATION: `organization`,
    ADDRESS: `address`,
    DETAILED_ADDRESS: `detailed_addr`,
    SEGMENT: `segment`,
    STATUS: `status`,
    LABEL: `label`,
    PROFILE_PIC: `profile_pic`,
    ATTACHMENT: `attachment`,
    SOURCE: `source`,
    BOOKS_CONTACT_ID: `books_contact_id`,
    SOURCE_ID: `source_id`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    ACCOUNT: "account_id",
    CREATED_AT: `created_at`,
    UPDATED_AT: `updated_at`,
    NOTE: `note`,
    LEAD_SCORE: `lead_score`,
    DOCUMENT_SEQUENCE_CODE: "seq_code",
    SEQUENCE_FORMAT: "seq_format",
    PRICE_LIST: "price_list_id"
  },
  CAMPAIGN: {
    NAME: `name`,
    TYPE: `type`,
    PUBLISHED_ON: `published_on`,
    DELIVERED: `delivered`,
    OPEN: `open`,
    CLICKS: `clicks`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    PUBLISHED: `published`,
    CAMPAIGN_TYPE: `campaign_type`,
    DIRECT_LINK: `direct_link`,
    SEGMENT_ID: `segment_id`,
    EMAIL_SUBJECT: `email_subject`,
    EMAIL_FROM: `email_from`,
    EMAIL_PRE_HEADER: `email_preheader`,
    HASH: `hash`,
    SEND_NOW: `send_now`,
    SMTP_SETUP_ID: `smtp_setup_id`
  },
  FORMS: {
    NAME: `name`,
    EMBED_URL: `embed_url`,
    DOMAIN: `domain`,
    VISITORS: `visitors`,
    CLICKS: `clicks`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    DIRECT_LINK: `direct_link`,
    SEGMENT_ID: `segment_id`,
    PUBLISHED: `published`,
    HASH: `hash`,
    SLUG: `slug`,
    CUSTOM_DOMAIN: "custom_domain"
  },
  LANDING_PAGE: {
    NAME: `name`,
    DOMAIN: `domain`,
    VISITORS: `visitors`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    DIRECT_LINK: `direct_link`,
    PUBLISHED: `published`,
    SEGMENT_ID: `segment_id`,
    HASH: `hash`,
    SLUG: `slug`,
    CUSTOM_DOMAIN: `custom_domain`
  },
  PIPELINE: {
    NAME: `name`,
    STAGE_ID_S: `stage_id_s`,
    IS_DEFAULT: `is_default`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    IS_PRIMARY: `is_primary`
  },
  STAGES: {
    NAME: `name`,
    ORDER: `order`,
    WON_STAGE: `won_stage`,
    LOST_STAGE: `lost_stage`,
    HELP_TEXT: `help_text`
  },
  DEAL: {
    NAME: `name`,
    CLOSING_DATE: `closing_date`,
    STAGE_ID: `stage_id`,
    PIPELINE_ID: `pipeline_id`,
    AMOUNT: `amount`,
    CONTACT_ID: `contact_id`,
    DEAL_DATE: `deal_date`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    VISIBILITY: `visibility`,
    ORGANIZATION_ID: `organization_id`,
    STATUS: `status`,
    REASON: `reason`,
    SOURCE: "source",
    SOURCE_ID: "source_id",
    NOTE: `note`,
    ATTACHMENT: `attachment`,
    CREATED_AT: `created_at`,
    PRIMARY_QUOTE_AMOUNT: `primary_quote_amount`,
    PRIMARY_QUOTE_NUMBER: `primary_quote_number`,
    CURRENCY_CODE: `currency`,
    EXCHANGE_RATE: `exchange_rate`,
    ACCOUNT_ID: `account_id`,
    DOCUMENT_SEQUENCE_CODE: "seq_code",
    SEQUENCE_FORMAT: "seq_format"
  },
  ACTIVITY: {
    NAME: `name`,
    ACTIVTY_TYPE: `activity_type`,
    START_DATE: `start_date`,
    END_DATE: `end_date`,
    NOTES: `notes`,
    ASSIGNEE_IDS: `assignee_ids`,
    CONTACT_IDS: `contact_ids`,
    ORGANIZATION_IDS: `organization_ids`,
    LINKED_TO: `linked_to`,
    STATUS: `status`,
    SEND_INVITATION: `send_invitation`,
    SEND_CONTACTS_INVITATION: `send_contacts_invitation`,
    OWNER_ID: `owner_id`,
    TIMEZONE: `timezone`,
    ACCOUNT_ID: `account_id`,
    DOCUMENT_SEQUENCE_CODE: "seq_code",
    SEQUENCE_FORMAT: "seq_format"
  },
  FUNNEL: {
    NAME: `name`,
    DOMAIN: `domain`,
    VISITORS: `visitors`,
    LEADS: `leads`,
    CLICKS: `clicks`,
    STATUS: `status`,
    HTML_DATA: `html_data`,
    JSON_DATA: `json_data`,
    THUMBNAIL: `thumbnail`,
    DIRECT_LINK: `direct_link`,
    PUBLISHED: `published`,
    SEGMENT_ID: `segment_id`,
    HASH: `hash`,
    CUSTOM_DOMAIN: "custom_domain"
  },
  AUTOMATION: {
    NAME: `name`,
    CREATED_ON: `created_on`,
    SCHEDULED_ON: `scheduled_on`,
    LAST_RUN_DATE: `last_run_date`,
    JSON_DATA: `json_data`,
    STATUS: `status`,
    CONNECTION_ID: `workflow_connection_id`
  },
  PRODUCT: {
    NAME: `name`,
    DESCRIPTION: `description`,
    PRICE: `price`
  },
  PRODUCTS: {
    Number: "documentSequenceCode",
    Description: "description",
    ProductName: "name",
    AvailableStock: "outgoingQty",
    Status: "status",
    Category: "type",
    Id: "id",
    IMAGES: `images`,
    Reorder: "reorder",
    Outgoing: "outgoing",
    TransactionType: "productTransactionType",
    PurchasePrice: "purchasePrice",
    Barcode: "barcode"
  },
  ORDER: {
    PRODUCT_ID: "products_ids",
    TRANSACTION_ID: "txn_id",
    CUSTOMER: "customer",
    NAME: "name",
    EMAIL: "email",
    PHONE: "phone",
    STREET_ADDRESS: "street_address",
    CITY: "city",
    STATE: "state",
    COUNTRY: "country",
    LANDMARK: "landmark",
    "POSTAL CODE": "postal_code"
  },
  IMPORT_LOG: {
    MODULE: "module",
    RECORDS: "totalRecords",
    FILE_NAME: "fileName",
    FILE_TYPE: "fileType",
    IMPORTED_BY: "importedBy",
    IMPORTED_ON: "createdAt"
  },
  AUTOMATION_LOGS: {
    AUTOMATION: "automationName",
    TRIGGER_TYPE: "triggerNodeType",
    ACTION_TYPE: "executedNodeType",
    LOG: "log",
    EXECUTED_AT: "executedAt"
  },
  BOOKS_QUOTE: {
    ID: "id",
    NUMBER: "documentSequenceCode",
    CONTACT: "contact",
    CONTACT_CODE: "contactCode",
    QUOTE_DATE: "documentDate",
    DUE_DATE: "validTillDate",
    TOTAL_AMOUNT: "totalAmount",
    FULFILLMENT: "fulfillmentStatus",
    APPROVAL_STATUS: "approvalStatus",
    CREATED_BY: "createdBy",
    STATUS: "status",
    ATTACHMENTS: "attachments",
    RESERVED_STOCK: "reservedStock",
    DOCUMENT_TYPE: "recurring",
    BILL_TO: "billTo",
    SHIP_TO: "shipTo",
    CRM3DEAL: "crm3DealId",
    CRM3DEALNAME: "crm3DealName",
    CRM3_DEAL_OWNER: "crm3DealOwnerIds"
  },
  ACCOUNT: {
    NAME: `name`,
    EMAIL: `email`,
    PHONE: `phone`,
    ADDRESS: `address`,
    WEBSITE_URL: `website_url`,
    ADDITIONAL_INFO: `additional_info`,
    ATTACHMENT: `attachment`,
    NOTE: `note`,
    OWNER_ID: `owner_id`,
    SUB_OWNER_ID: `sub_owner`,
    BOOKS_CONTACT_ID: `books_contact_id`,
    DETAILED_ADDRESS: `detailed_addr`,
    CONTACT_COUNT: `contactCount`,
    DEAL_COUNT: `dealsCount`,
    WON_DEAL_AMOUNT: `wonDealAmount`,
    LOST_DEAL_AMOUNT: `lostDealAmount`,
    PRICE_LIST: "price_list_id",
    PRIMARY_QUOTE_WON_DEAL: "primaryQuoteWonDealAmount",
    PRIMARY_QUOTE_LOST_DEAL: "primaryQuoteLostDealAmount",
    DOCUMENT_SEQUENCE_CODE: "seq_code",
    SEQUENCE_FORMAT: "seq_format"
  },
  AUDIT_LOG: {
    ACTIONS: "actionName",
    ACTION_BY: "actionBy",
    ACTION_DATE: "actionDate",
    LOG: "log",
    OLD_VALUES: "old_values"
  },
  BOOKS_INVOICE: {
    NUMBER: "documentSequenceCode",
    CONTACT: "contact",
    INVOICE_DATE: "salesInvoiceDate",
    DUE_DATE: "salesInvoiceDueDate",
    TOTAL_AMOUNT: "totalAmount",
    DUE_AMOUNT: "dueAmount",
    FULFILLMENT: "fulfillmentStatus",
    PAYMENT: "paymentStatus",
    APPROVAL_STATUS: "approvalStatus",
    CREATED_BY: "createdBy",
    DOCUMENT_TYPE: "recurring",
    E_INVOICING: "invoiceNow",
    STATUS: "status"
  },
  DRAFTS: {
    ID: "id",
    NAME: "name",
    TYPE: "type",
    PAYLOAD: "payload",
    IS_MAXIMIZED: "is_maximized",
    IS_CENTER_ALIGNED: "is_center_align",
    IS_SAVED: "is_saved",
    APP_NAME: "app_name"
  },
  TEMPLATE: {
    NAME: "name",
    HTML_DATA: "html_data",
    TAGS: "tags"
  },
  BOOKS_PRICE_LIST: {
    NAME: "name",
    TYPE: "type",
    EFFECTIVE_DATE: "Date",
    CURRENCY: "currency",
    STATUS: "status",
    LINE_ITEM_NAME: "productName",
    LINE_ITEM_CODE: "productCode",
    LINE_ITEM_PRICE: "price",
    LINE_ITEM_PRICE_CURRENCY: "priceCurrency",
    LINE_ITEM_UOM: "uomName",
    LINE_ITEM_MIN_QTY: "minimumQuantity",
    LINE_ITEM_DISCOUNT: "discount",
    LINE_ITEM_DISCOUNT_IN_PERCENT: "discountInPercent",
    CREATED_BY: "createdBy"
  },
  CURRENCY: {
    Currency: "currency",
    Code: "code",
    Symbol: "symbol",
    ExchangeRate: "exchangeRate",
    ExchangeRateDate: "exchangeRateDate",
    Visibility: "visibility",
    Id: "id"
  },
  TICKET: {
    ASSIGNEE: "assignee",
    ASSIGNEE_NAME: "assignee_name",
    TYPE: "type",
    PRIORITY: "priority",
    STATUS: "status",
    CREATED_BY: "created_by",
    CONTACT_ID: "crm3_contact_id",
    ACCOUNT_ID: "crm3_account_id"
  }
};
export const TABLE_DISPLAY_NAME = {
  [TABLES.CONTACT]: `Contacts`,
  [TABLES.SEGMENT]: `Contacts`,
  [TABLES.DEAL]: `Deals`,
  [TABLES.CAMPAIGN]: `Campaigns`,
  [TABLES.FORM]: `Forms`,
  [TABLES.LANDING_PAGE]: `Landing Pages`,
  [TABLES.AUTOMATION]: `Automations`,
  [TABLES.FUNNEL]: `Funnels`,
  [TABLES.ORDER]: `Orders`,
  [TABLES.IMPORT_LOG]: `Import Logs`,
  [TABLES.ACCOUNT]: "Accounts",
  [TABLES.ACTIVITY]: `Activities`,
  [TABLES.AUTOMATION_LOG]: `Automation Logs`,
  [TABLES.BOOKS_QUOTE]: `Quotes`,
  [TABLES.AUDIT_LOG]: `Audit Logs`,
  [TABLES.TICKET]: `Ticket`,
  [TABLES.SHOP_LEAD]: `Shop leads`,
  [TABLES.BOOKS_PRICE_LIST_LOG]: `Price Book Logs`,
  [TABLES.CURRENCY]: `Multi Currency`
};

export const MODULE_ACTIONS = {
  ADD: "ADD",
  EMAIL: "EMAIL"
};
export const RESTRICTED_MODULE_ACTIONS_IN_TRIAL = {
  [MODULE_ACTIONS.ADD]: [TABLES.LANDING_PAGE, TABLES.FUNNEL],
  [MODULE_ACTIONS.EMAIL]: [
    TABLES.AUTOMATION,
    TABLES.CONTACT,
    TABLES.CAMPAIGN,
    TABLES.TICKET
  ]
};

export class TableManger {
  static tableMap = new Map();
  static tableIdNameMap = new Map();
  static reportTablesData = null;
  static getAllReportsTableData() {
    return TableManger.reportTablesData;
  }
  static setAllReportsTableData(data) {
    TableManger.reportTablesData = data;
  }
  static performInitialColumnUpdates(tableName?: string) {
    TableDataParser.putDataInOwnersColumn(store.getState().tenant?.users);
    switch (tableName) {
      case TABLES.DEAL:
        TableDataParser.updateDealColumns();
        TableDataParser.updateAccountColumnInDeal();
        TableDataParser.updateLookupDealFields();
        break;
      case TABLES.ACCOUNT:
        TableDataParser.updateLookupAccountFields();
        TableDataParser.updatePriceBookColumnInAccount();
        break;
      case TABLES.CONTACT:
        TableDataParser.checkAndReplaceSegment();
        TableDataParser.updateAccountColumnInContacts();
        TableDataParser.updateLookupContactFields();
        TableDataParser.updatePriceBookColumnInContacts();
        break;
      case TABLES.ACTIVITY:
        TableDataParser.checkAndReplaceActivityData(
          store.getState().records?.data[TABLES.ACTIVITY]?.data,
          store.getState().tenant?.users
        );
        TableDataParser.updateLookupActivityFields();
        break;
      default:
        TableDataParser.updateLookupContactFields();
        TableDataParser.updateLookupAccountFields();
        TableDataParser.updateLookupActivityFields();
        TableDataParser.updateLookupDealFields();
        TableDataParser.updateAccountColumnInContacts();
        TableDataParser.updatePriceBookColumnInContacts();
        TableDataParser.updatePriceBookColumnInAccount();
        TableDataParser.updateAccountColumnInDeal();
        TableDataParser.updateDealColumns();
        TableDataParser.checkAndReplaceSegment();
        TableDataParser.checkAndReplaceActivityData(
          store.getState().records?.data[TABLES.ACTIVITY]?.data,
          store.getState().tenant?.users
        );
        Utility.isEmptyObject(tableName) &&
          TableDataParser.fetchAndUpdateCustomTicketFields();
    }
  }
  static setTableMap(parsedTableData, tableName: string) {
    this.tableMap.set(tableName, parsedTableData);

    if (tableName === TABLES.CONTACT) {
      this.tableMap.set(TABLES.SEGMENT, parsedTableData);
    } else if (tableName === TABLES.SEGMENT) {
      this.tableMap.set(TABLES.CONTACT, parsedTableData);
    }
  }
  static populateViewMap(data) {
    data = [
      {
        _id: getRandomAlphaNumericString(),
        name: TABLES.TICKET,
        columnsMetaData: TICKET_COLUMNS
      },
      ...data
    ];

    data.forEach((item) => {
      const parsedTableData = TableDataParser.parseTableData(item);
      this.tableIdNameMap.set(item._id, item.name);
      this.setTableMap(parsedTableData, item.name);
    });

    this.performInitialColumnUpdates("");
  }
  static setTableColumns(columnData, tableName: string) {
    const parsedTableData = {
      ...this.tableMap.get(tableName),
      ...TableDataParser.parseTableData(columnData)
    };
    this.setTableMap(parsedTableData, tableName);
    this.performInitialColumnUpdates(tableName);
  }
  static updateColumn(columnCode, tableName, columnData) {
    let columnsIndex = this.tableMap
      .get(tableName)
      .columns.findIndex((column) => column.columnCode === columnCode);
    let columns = this.tableMap.get(tableName).columns;
    columns.splice(columnsIndex, 1, columnData);

    const updatedTableData = { ...this.tableMap.get(tableName), columns };
    this.setTableMap(updatedTableData, tableName);
  }
  static updateColumnById(colId, tableName, columnData) {
    let columnsIndex = this.tableMap
      .get(tableName)
      .columns.findIndex((column) => column.id === colId);
    let columns = this.tableMap.get(tableName).columns;
    columns.splice(columnsIndex, 1, columnData);
    const updatedTableData = { ...this.tableMap.get(tableName), columns };
    this.setTableMap(updatedTableData, tableName);
  }
  static getTableId(tableName: string): string {
    return this.tableMap.get(tableName)?.id;
  }
  static getTableName(tableName: string): string {
    return this.tableMap.get(tableName).name;
  }
  static getTableNameFromId(tableId: string): string {
    return this.tableIdNameMap.get(tableId);
  }
  static getTableColumns(tableName: string): IColumn[] {
    return this.tableMap.get(tableName)?.columns;
  }
  static getColumnCodeKeyMap(tableName: string): Map<string, string> {
    return this.tableMap.get(tableName).columns.reduce((acc, column) => {
      acc[column.columnCode] = column.key;
      return acc;
    }, {});
  }
  static getTableVisibleColumns(tableName: string): IColumn[] {
    return this.getTableColumns(tableName).filter((column) => !column.hidden);
  }
  static getTableImportColumns(
    tableName: string,
    excludedColumns?: Array<string>
  ): IColumn[] {
    return this.getTableColumns(tableName)
      .filter((column) => {
        const isExcluded =
          excludedColumns && excludedColumns.includes(column.columnCode);
        const isEditable = column.uiVisible && column.editable;

        return !isExcluded && isEditable;
      })
      .sort((col1: IColumn, col2: IColumn) => col1.index - col2.index);
  }
  static getTableFilteredColumns(
    tableName: string,
    condition?: Function,
    filterEditable?: boolean
  ): IColumn[] {
    const columns = this.getTableColumns(tableName);

    if (!columns || !Array.isArray(columns)) {
      console.warn(`No columns found for table: ${tableName}`);
      return [];
    }

    return columns.filter((column) => {
      const isEditable = column.uiVisible && column.editable;

      if (condition) {
        const isIncluded = condition(column);
        return filterEditable ? isIncluded && isEditable : isIncluded;
      } else {
        return filterEditable ? isEditable : true;
      }
    });
  }

  static getColumnId(tableName: string, columnCode: string): string {
    let column = this.getColumn(tableName, columnCode);
    return column ? column.id : undefined;
  }
  static getColumn(tableName: string, columnCode: string): IColumn {
    return this.getTableColumns(tableName)?.find(
      (column) => column.columnCode === columnCode
    );
  }
  static getFilterObject(
    data,
    logicalOperator?: FILTER_LOGICAL_OPERATORS
  ): ITableFilter {
    let filterObject: ITableFilter = {
      logicalOperator: logicalOperator ?? FILTER_LOGICAL_OPERATORS.AND,
      conditions: []
    };
    data.forEach((item) =>
      filterObject.conditions.push(this.getFilterSubCondition(item))
    );
    return filterObject;
  }
  static getFilterSubCondition(item): IFilterCondition {
    return {
      colId: item.key,
      value: item.value,
      opr: item.condition,
      isExternal: item.isExternal ?? false
    };
  }
  static isSelectField(column) {
    return (
      column.type === INPUT_TYPE.SELECT ||
      column.type === INPUT_TYPE.MULTI_SELECT ||
      column.type === "ref"
    );
  }

  static refreshTable = async (tableName: string) => {
    return store.dispatch(fetchTableByName({ tableName })).then((res) => {
      if (!Utility.isEmptyObject(res?.payload)) {
        TableManger.setTableColumns(
          Utility.deepCloneObject(res.payload),
          tableName
        );
      }
    });
  };
  static getOptionsFromDataSource(dataSource: IColumnDataSource) {
    const targetTableName = dataSource?.target
      ? this.getTableNameFromId(dataSource.target)
      : ``;
    const sourceColumnKey = (dataSource.valueKey || "").replace("cells.", "");

    if (!this.tableMap.has(targetTableName) || !sourceColumnKey) return;

    const sourceColumnData = this.getTableColumns(targetTableName)?.find(
      (column: IColumn) => column.id === sourceColumnKey
    );

    return sourceColumnData?.options || [];
  }
  static convertDataKeysToDisplayName(dataArray) {
    if (dataArray.length > 0) {
      let element = dataArray[0];
      let allKeys = Object.keys(element);
      let getTableName = allKeys[0].split("__")[0];

      let newDataArray = dataArray.map((item) => {
        let newItem = {};
        for (let i = 0; i < allKeys.length; i++) {
          let keyName = allKeys[i];
          let tableName = keyName.split("__")[0];
          let newKeyName = keyName.split("__")[1];
          if (
            !Utility.isEmptyObject(
              TableManger.getColumnDisplayName(newKeyName, tableName)
            )
          ) {
            newItem[TableManger.getColumnDisplayName(newKeyName, tableName)] =
              item[keyName];
          }
        }
        return newItem;
      });
      return newDataArray;
    }
    return [];
  }
  static getColumnDisplayName(columnName, tableName) {
    let columnData = [];
    let tableData = TableManger.getAllReportsTableData()?.filter(
      (obj) => obj.tableName === tableName
    );
    if (tableData[0] && tableData[0].fields) {
      columnData = tableData[0].fields.filter((obj) => obj.name === columnName);
    }
    if (columnData && columnData[0]) {
      return columnData[0].display;
    }
    return "";
    // return columnData[0].display;
  }
  static getColumnById(tableName: string, columnId: string): IColumn {
    return this.getTableColumns(tableName)?.find(
      (column) => column.id === columnId
    );
  }
}
