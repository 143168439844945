import { DKLabel, TOAST_TYPE, Toggle, showAlert } from "deskera-ui-library";
import { useEffect, useState } from "react";
import {
  SETTING_MODULE,
  TenantInfoSeqCodeStatus
} from "../../constants/Constant";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  fetchCRMSettings,
  selectCRMSettings
} from "../../redux/slices/tenantSlice";
import { updateTenantSettings } from "../../services/tenant";
import Utility from "../../utility/Utility";
import { selectPermissionsByModule } from "../../redux/slices/rolesPermissionSlice";
import { USER_ACTION_TYPES } from "../../constants/Permission";
import { TABLES } from "../../managers/TableManger";

export interface ISequenceCodeSettings {
  // onClose: () => void;
}

export default function SequenceCodeSettings(props: ISequenceCodeSettings) {
  const dispatch = useAppDispatch();
  const crmSettings = useAppSelector(selectCRMSettings());
  const [contactSeqCodeToggle, setContactSeqCodeToggle] = useState(false);
  const [dealSeqCodeToggle, setDealSeqCodeToggle] = useState(false);
  const [accountSeqCodeToggle, setAccountSeqCodeToggle] = useState(false);
  const [activitySeqCodeToggle, setActivitySeqCodeToggle] = useState(false);
  const settingPermissions = useAppSelector((state) =>
    selectPermissionsByModule(state, SETTING_MODULE)
  );

  const saveSequenceCodeSettings = async (table: string) => {
    try {
      let payload = {};
      if (table === TABLES.CONTACT) {
        payload = {
          contactSeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      if (table === TABLES.DEAL) {
        payload = {
          dealSeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      if (table === TABLES.ACCOUNT) {
        payload = {
          accountSeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      if (table === TABLES.ACTIVITY) {
        payload = {
          activitySeqCodeStatus: TenantInfoSeqCodeStatus.ACTIVE
        };
      }
      const response = await updateTenantSettings(payload);
      if (!Utility.isEmptyObject(response)) {
        dispatch(fetchCRMSettings({}));
        // props?.onClose?.();
      }
    } catch (error) {
      // Handle error
      console.error(error);
    }
  };

  useEffect(() => {
    setContactSeqCodeToggle(
      crmSettings?.contactSeqCodeStatus === TenantInfoSeqCodeStatus.ACTIVE
    );
    setAccountSeqCodeToggle(
      crmSettings?.accountSeqCodeStatus === TenantInfoSeqCodeStatus.ACTIVE
    );
    setDealSeqCodeToggle(
      crmSettings?.dealSeqCodeStatus === TenantInfoSeqCodeStatus.ACTIVE
    );
    setActivitySeqCodeToggle(
      crmSettings?.activitySeqCodeStatus === TenantInfoSeqCodeStatus.ACTIVE
    );
  }, []);

  return (
    <div className="column parent-width border-m border-radius-m position-relative p-r mt-l">
      <div className="column parent-width">
        <div className="row parent-width justify-content-between mb-s">
          <div className="column">
            <DKLabel
              text={`Enable Sequence Code`}
              className="mr-m mb-xs fw-m"
            />
            <DKLabel
              className="text-gray text-ellipsis"
              text="You cannot disable sequence code once it is enabled"
            />
          </div>
          <div className="ml-l"></div>
        </div>
        {settingPermissions[USER_ACTION_TYPES.CONTACT_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="column">
              <DKLabel text={`Contact`} className="mr-m mb-xs fw-m" />
            </div>
            <div className="ml-l">
              <Toggle
                isOn={contactSeqCodeToggle}
                onChange={() => {
                  if (!contactSeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setContactSeqCodeToggle(true);
                            saveSequenceCodeSettings(TABLES.CONTACT);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={contactSeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
        {settingPermissions[USER_ACTION_TYPES.ACCOUNT_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="column">
              <DKLabel text={`Account`} className="mr-m mb-xs fw-m" />
            </div>
            <div className="ml-l">
              <Toggle
                isOn={accountSeqCodeToggle}
                onChange={() => {
                  if (!accountSeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setAccountSeqCodeToggle(true);
                            saveSequenceCodeSettings(TABLES.ACCOUNT);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={accountSeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
        {settingPermissions[USER_ACTION_TYPES.DEAL_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="column">
              <DKLabel text={`Deal`} className="mr-m mb-xs fw-m" />
            </div>
            <div className="ml-l">
              <Toggle
                isOn={dealSeqCodeToggle}
                onChange={() => {
                  if (!dealSeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setDealSeqCodeToggle(true);
                            saveSequenceCodeSettings(TABLES.DEAL);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={dealSeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
        {settingPermissions[USER_ACTION_TYPES.DEAL_SEQ_CODE] && (
          <div className="row parent-width justify-content-between mt-s">
            <div className="column">
              <DKLabel text={`Activity`} className="mr-m mb-xs fw-m" />
            </div>
            <div className="ml-l">
              <Toggle
                isOn={activitySeqCodeToggle}
                onChange={() => {
                  if (!activitySeqCodeToggle) {
                    showAlert(
                      "Warning!",
                      "After enabling this setting, you will not be able to disable it.",
                      [
                        {
                          title: "Cancel",
                          className: "bg-white border-m mr-s",
                          onClick: () => {}
                        },
                        {
                          title: "Proceed",
                          className: "bg-button text-white",
                          onClick: () => {
                            setActivitySeqCodeToggle(true);
                            saveSequenceCodeSettings(TABLES.ACTIVITY);
                          }
                        }
                      ]
                    );
                  } else {
                    showAlert("", "This setting cannot be disabled.");
                  }
                }}
                className=""
                color="bg-green"
                disabled={activitySeqCodeToggle} // This will disable the toggle when it's on
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
