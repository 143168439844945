import { getCurrencySymbolFromCode } from "../constants/Currencies";
import { AUDIT_LOG_EMPTY_DISPLAY_VALUE, OBJECT_TYPE } from "../constants/Enum";
import { COLUMN_CODE, TABLES, TableManger } from "../managers/TableManger";
import { IColumn } from "../model/Table";
import { store } from "../redux/store";
import Utility from "../utility/Utility";
import { DateUtil } from "../utility/Date";

export class AuditHelper {
  static getTableColumns = (props) => {
    return props
      ? TableManger.getTableFilteredColumns(
          props,
          (col) =>
            !col.hidden ||
            [COLUMN_CODE.DEAL.PIPELINE_ID].includes(col.columnCode)
        ) || []
      : [];
  };

  static getTableColumnsWithHidden = (props) => {
    return props
      ? TableManger.getTableFilteredColumns(
          props,
          (col) =>
            col.type !== "json_array" 
        ) || []
      : [];
  };
  

  static getColumnValue = (columnCode: string, rowData: any) => {
    let columnID = TableManger.getColumnId(
      TABLES[rowData?.objectType],
      columnCode
    );
    if (Utility.isEmptyObject(columnID)) return AUDIT_LOG_EMPTY_DISPLAY_VALUE;
    return rowData.metaData?.recordsAfter?.[0]?.cells[columnID];
  };

  static getSegmentDisplayValue = (
    columnCode: string,
    columnsMetaData,
    rowData: any
  ) => {
    let ColumnType = columnsMetaData?.find(
      (e) => e?.columnCode === columnCode || e?.id === columnCode
    );
    if (Utility.isEmptyObject(ColumnType)) return AUDIT_LOG_EMPTY_DISPLAY_VALUE;
    let SelectValAfter =
      rowData.metaData.recordsAfter?.[0]?.cells[ColumnType?.id];
    let contactSegmentColumn = this.getTableColumns(TABLES.CONTACT)?.find(
      (e) => e?.columnCode === COLUMN_CODE.CONTACT.SEGMENT
    );
    let colValue = SelectValAfter?.map((val) => {
      let option = contactSegmentColumn?.options?.find(
        (opt) => opt?.id === val
      );
      return option?.name || AUDIT_LOG_EMPTY_DISPLAY_VALUE;
    }).join(", ");
    return colValue;
  };

  static getFormattedDate = (date) => {
    const newDate = DateUtil.getDateStrFromDate(
      new Date(date),
      DateUtil.getOrgDateFormat()
    );
    return `${DateUtil.getFormattedDateString(
      newDate,
      DateUtil.getOrgDateFormat(),
      DateUtil.getOrgDateFormat()
    )}, ${Utility.getFormattedTime(new Date(date))}`;
  };

  static getLogRenderForObjectType = (
    objectType: string,
    rowData: any,
    columnsMetaData: any
  ) => {
    let displayRenderValueObject = rowData?.metaData?.recordsAfter?.[0]?.cells;

    //Default Name Column
    let getDisplayColumnList = [
      TableManger.getColumnId(
        objectType?.toLowerCase(),
        COLUMN_CODE.CONTACT.NAME
      )
    ];

    //Expected Name Include By Module
    if (OBJECT_TYPE_PREDEFINED_COLUMNS[objectType]) {
      OBJECT_TYPE_PREDEFINED_COLUMNS[objectType]?.forEach((column) => {
        getDisplayColumnList.push(
          TableManger.getColumnId(objectType?.toLowerCase(), column)
        );
      });
    }
    let originalColumnList =
      store.getState().table.data?.[objectType?.toLowerCase()]
        ?.columnsMetaData ?? [];
    let getColumnTitleAndValue = [];
    columnsMetaData.forEach((column) => {
      if (
        getDisplayColumnList.includes(column.id) &&
        displayRenderValueObject?.[column.id]
      ) {
        let currencyColCode = TableManger.getColumnId(
          TABLES.DEAL,
          COLUMN_CODE.DEAL.CURRENCY_CODE
        );
        let dealAmountColCode = TableManger.getColumnId(
          TABLES.DEAL,
          COLUMN_CODE.DEAL.AMOUNT
        );
        let publishedDateColCode = TableManger.getColumnId(
          TABLES.CAMPAIGN,
          COLUMN_CODE.CAMPAIGN.PUBLISHED_ON
        );
        let segmentIdColCode = TableManger.getColumnId(
          TABLES.CAMPAIGN,
          COLUMN_CODE.CAMPAIGN.SEGMENT_ID
        );
        let activityTypeIdColCode = TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE
        );
        let activityStartDateIdColCode = TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.START_DATE
        );
        let activityEndDateIdColCode = TableManger.getColumnId(
          TABLES.ACTIVITY,
          COLUMN_CODE.ACTIVITY.END_DATE
        );
        let columnValue = displayRenderValueObject?.[column.id];
        switch (column.id) {
          case dealAmountColCode:
            columnValue =
              getCurrencySymbolFromCode(
                displayRenderValueObject?.[currencyColCode]
              ) +
              " " +
              displayRenderValueObject?.[column.id];
            break;
          case publishedDateColCode:
            columnValue = DateUtil.getDateStrFromDate(
              new Date(displayRenderValueObject?.[column.id]),
              DateUtil.getOrgDateFormat()
            );
            break;
          case segmentIdColCode:
            columnValue = this.getSegmentDisplayValue(
              segmentIdColCode,
              columnsMetaData,
              rowData
            );
            break;
          case activityTypeIdColCode:
            let columnData = columnsMetaData?.find(
              (column) => column.id === activityTypeIdColCode
            );
            let typeObject = columnData?.options.find(
              (option) => option.id === columnValue
            );
            columnValue = typeObject?.name;
            break;
          case activityStartDateIdColCode:
          case activityEndDateIdColCode:
            columnValue = this.getFormattedDate(columnValue);
            break;
          default:
            break;
        }

        getColumnTitleAndValue.push({
          title: column?.name,
          value: columnValue ?? AUDIT_LOG_EMPTY_DISPLAY_VALUE
        });
      }
    });

    //Include Common Ref Columns
    originalColumnList?.forEach((column) => {
      if (
        AUDIT_REF_COLUMN_TYPE_INCLUDE.includes(column.type) &&
        !Utility.isEmptyObject(
          displayRenderValueObject?.[column?.id + "_detail"]
        )
      ) {
        let refTableColumnId = TableManger.getColumnId(
          column?.refTable?.objectType?.toLowerCase(),
          COLUMN_CODE.ACCOUNT.NAME
        );
        getColumnTitleAndValue.push({
          title: column?.name,
          value:
            displayRenderValueObject?.[column?.id + "_detail"]?.[0]?.name ??
            displayRenderValueObject?.[column?.id + "_detail"]?.[0]?.cells?.[
              refTableColumnId
            ] ??
            AUDIT_LOG_EMPTY_DISPLAY_VALUE
        });
      }
    });

    return getColumnTitleAndValue;
  };
}

export const AUDIT_REF_COLUMN_TYPE_INCLUDE = ["ref", "user", "user_array"];

export const OBJECT_TYPE_PREDEFINED_COLUMNS = {
  [OBJECT_TYPE.CONTACT]: [
    COLUMN_CODE.CONTACT.EMAIL,
    COLUMN_CODE.CONTACT.ADDRESS
  ],
  [OBJECT_TYPE.DEAL]: [COLUMN_CODE.DEAL.AMOUNT],
  [OBJECT_TYPE.ACTIVITY]: [
    COLUMN_CODE.ACTIVITY.START_DATE,
    COLUMN_CODE.ACTIVITY.END_DATE,
    COLUMN_CODE.ACTIVITY.ACTIVTY_TYPE
  ],
  [OBJECT_TYPE.CAMPAIGN]: [
    COLUMN_CODE.CAMPAIGN.SEGMENT_ID,
    COLUMN_CODE.CAMPAIGN.PUBLISHED_ON
  ]
};

export const AUDIT_LOG_COLUMNS: IColumn[] = [
  {
    name: "Action",
    id: "actionName",
    type: "text",
    index: 0,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "actionName",
    key: "actionName"
  },
  {
    name: "User",
    id: "actionBy",
    type: "text",
    index: 1,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "actionBy",
    key: "actionBy"
  },
  {
    name: "Action Date",
    id: "actionDate",
    type: "text",
    index: 2,
    options: null,
    required: false,
    width: 250,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "actionDate",
    key: "actionDate"
  },
  {
    name: "New Values",
    id: "log",
    type: "text",
    index: 3,
    options: null,
    required: false,
    width: 350,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "log",
    key: "log"
  },
  {
    name: "Old Values",
    id: "executedAt",
    type: "text",
    index: 4,
    options: null,
    required: false,
    width: 350,
    editable: false,
    hidden: false,
    uiVisible: true,
    systemField: true,
    columnCode: "old_values",
    key: "old_values"
  }
];
