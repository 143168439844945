import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDocumentApprovalNode } from "../../model/Quotation";
import BooksService from "../../services/books";
import { PrintService } from "../../services/common/print";
import NumberFormatService from "../../services/numberFormat";
import Tenant from "../../services/tenant";
import debounce from "../../utility/Debounce";

export interface BooksState {
  products: any;
  productsByPriceBookId: any;
  taxes: any;
  quotes: any;
  sequenceFormats: any;
  contactSequenceFormats: any;
  accountSequenceFormats: any;
  dealSequenceFormats: any;
  activitySequenceFormats: any;
  taxAccount: any;
  accounts: any;
  tenantsInfo: any;
  tenantsDetails: any;
  templatePrintInfo: any;
  hasTenantFinanceDetails: boolean;
  customPrintTemplates: {
    list: any[];
    selected: any;
  };
  documentData: [];
  invoiceSummary: {};
  uoms: any[];
  productCustomFields: any;
  quoteCustomFields: any;
  approvalFlows: IDocumentApprovalNode[];
  additionalCharges: any;
}

const initialState: BooksState = {
  products: null,
  productsByPriceBookId: null,
  taxes: null,
  hasTenantFinanceDetails: false,
  quotes: {},
  sequenceFormats: [],
  contactSequenceFormats: [],
  accountSequenceFormats: [],
  dealSequenceFormats: [],
  activitySequenceFormats: [],
  taxAccount: [],
  accounts: [],
  tenantsInfo: {},
  tenantsDetails: {},
  templatePrintInfo: null,
  customPrintTemplates: {
    list: [],
    selected: null
  },
  documentData: [],
  invoiceSummary: {},
  uoms: [],
  productCustomFields: [],
  quoteCustomFields: [],
  approvalFlows: [],
  additionalCharges: []
};
export const fetchBooksProducts = createAsyncThunk(
  "books/fetchBooksProducts",
  async (params?: any) => {
    const response = await BooksService.getProducts(params);
    return response;
  }
);
export const fetchBooksProductsByPriceBookId = createAsyncThunk(
  "books/fetchBooksProductsByPriceBookId",
  async (data?: any) => {
    const response = await BooksService.getProductsByPriceBookId(
      data.params,
      data.priceListId
    );
    return response;
  }
);
export const fetchBooksTaxes = createAsyncThunk(
  "books/fetchBooksTaxes",
  async (data, thunkAPI) => {
    const response = await BooksService.getTaxes();
    return response;
  }
);

export const fetchBooksQuotes = createAsyncThunk(
  "books/fetchBooksQuotes",
  async (data: any, thunkAPI) => {
    if (data.needDebounce) {
      debouncedFetchQuotes(data, thunkAPI);
    } else {
      const response: any = await BooksService.fetchQuotes(data?.params);
      return response;
    }
  }
);

export const debouncedFetchQuotes = debounce(async (data, thunkAPI) => {
  const response: any = await BooksService.fetchQuotes(data?.params);
  thunkAPI.dispatch(setQuotes(response));
}, 300);

export const fetchBooksQuotesByContactIds = createAsyncThunk(
  "books/fetchBooksQuotesByContactIds",
  async (data: any, thunkAPI) => {
    const { params, contactIds } = data || {};

    if (!contactIds?.length) return {};

    const response: any = await BooksService.fetchQuotesByContactIds(
      params,
      contactIds
    );
    return response;
  }
);

export const fetchBooksQuotesByContactAndDealIds = createAsyncThunk(
  "books/fetchBooksQuotesByContactAndDealIds",
  async (data: any, thunkAPI) => {
    const { params, payload } = data || {};
    const response: any = await BooksService.fetchQuotesByContactAndDealIds(
      params,
      payload
    );
    return response;
  }
);

export const fetchTaxAccount = createAsyncThunk(
  "books/fetchTaxAccount",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchTaxAccount(data);
    return response;
  }
);

export const fetchTenantsInfo = createAsyncThunk(
  "books/fetchTenantsInfo",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchTenantsInfo(data);
    return response;
  }
);
export const createContact = createAsyncThunk(
  "books/createContact",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.createContact(data.payload);
    return response;
  }
);
export const fetchSequenceFormats = createAsyncThunk(
  "books/fetchSequenceFormats",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchSequenceFormats(data.payload);
    return response;
  }
);
export const fetchContactSequenceFormats = createAsyncThunk(
  "books/fetchContactSequenceFormats",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchSequenceFormats(data.payload);
    return response;
  }
);
export const fetchAccountSequenceFormats = createAsyncThunk(
  "books/fetchAccountSequenceFormats",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchSequenceFormats(data.payload);
    return response;
  }
);
export const fetchDealSequenceFormats = createAsyncThunk(
  "books/fetchDealSequenceFormats",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchSequenceFormats(data.payload);
    return response;
  }
);
export const fetchActivitySequenceFormats = createAsyncThunk(
  "books/fetchActivitySequenceFormats",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchSequenceFormats(data.payload);
    return response;
  }
);
export const deleteQuote = createAsyncThunk(
  "books/deleteQuote",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.deleteQuote(data.payload);
    return response;
  }
);
export const fetchCustomPrintTemplates = createAsyncThunk(
  "books/fetchCustomPrintTemplates",
  async (data: any, thunkAPI) => {
    const response: any =
      await PrintService.getInvoiceDesignTemplatesByCategory(data);
    return response;
  }
);
export const fetchTemplatePrintInfo = createAsyncThunk(
  "books/fetchTemplatePrintInfo",
  async (data: any, thunkAPI) => {
    const response = await PrintService.getTemplatePrintInfo(data);
    return response;
  }
);
export const fetchTenantDetails = createAsyncThunk(
  "books/fetchTenantDetails",
  async (data: any, thunkAPI) => {
    const response: any = await Tenant.getTenantDetails();
    NumberFormatService.setNumberFormatter(
      response.numberFormat,
      response.decimalScale
    );
    return response;
  }
);
export const fetchBooksInvoices = createAsyncThunk(
  "books/fetchBooksInvoices",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchDocumentData(
      data?.params,
      data.documentType
    );
    return response;
  }
);
export const fetchBookDocumentsByContactIds = createAsyncThunk(
  "books/fetchBookDocumentsByContactIds",
  async (data: any, thunkAPI) => {
    const { params, documentType, contactIds } = data || {};

    if (!contactIds?.length) return {};

    const response: any = await BooksService.fetchDocumentsByContactIds(
      params,
      documentType,
      contactIds
    );
    return response;
  }
);
export const fetchBooksInvoiceSummary = createAsyncThunk(
  "books/fetchBooksInvoiceSummary",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchInvoiceSummary(data);
    return response;
  }
);

export const fetchBooksApprovalFlows = createAsyncThunk(
  "books/fetchBooksApprovalFlows",
  async (data: any, thunkAPI) => {
    const response: any = await BooksService.fetchBooksApprovalFlows();
    return response;
  }
);
export const fetchUOMs = createAsyncThunk("uoms", async () => {
  const response = await BooksService.getUOMs();
  return response.content;
});
export const fetchProductCustomFields = createAsyncThunk(
  "books/fetchProductCustomFields",
  async (data: any, thunkAPI) => {
    const response = await BooksService.getCustomFields(data);
    return response;
  }
);

export const fetchQuoteCustomFields = createAsyncThunk(
  "books/fetchQuoteCustomFields",
  async (data: any, thunkAPI) => {
    const response = await BooksService.getCustomFields(data);
    return response;
  }
);

export const fetchAllAdditionalCharge = createAsyncThunk(
  "allAdditionalCharge",
  async () => {
    let response = await BooksService.fetchAllAdditionalCharge();
    return response;
  }
);

export const booksSlice = createSlice({
  name: "records",
  initialState,
  reducers: {
    setSelectedPrintTemplate: (state, action: PayloadAction<any>) => {
      state.customPrintTemplates.selected = { ...action.payload };
    },
    setCustomPrintTemplates: (state, action: PayloadAction<any>) => {
      state.customPrintTemplates.list = action.payload;
    },
    setTemplatePrintInfo: (state, action: PayloadAction<any>) => {
      state.templatePrintInfo = action.payload;
    },
    setTenantFinanceDetails: (state, action: PayloadAction<any>) => {
      state.hasTenantFinanceDetails = action.payload;
    },
    setQuotes: (state, action) => {
      state.quotes = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchBooksProducts.fulfilled, (state, action: any) => {
      state.products = action.payload;
    });
    builder.addCase(
      fetchBooksProductsByPriceBookId.fulfilled,
      (state, action: any) => {
        state.productsByPriceBookId = action.payload;
      }
    );
    builder.addCase(fetchBooksTaxes.fulfilled, (state, action: any) => {
      state.taxes = action.payload;
    });
    builder.addCase(fetchBooksQuotes.fulfilled, (state, action) => {
      state.quotes = action.payload;
    });
    builder.addCase(fetchBooksQuotesByContactIds.fulfilled, (state, action) => {
      state.quotes = action.payload;
    });
    builder.addCase(
      fetchBooksQuotesByContactAndDealIds.fulfilled,
      (state, action) => {
        state.quotes = action.payload;
      }
    );
    builder.addCase(fetchTaxAccount.fulfilled, (state, action) => {
      state.taxAccount = action.payload;
    });
    builder.addCase(fetchTenantsInfo.fulfilled, (state, action) => {
      state.tenantsInfo = action.payload;
    });
    builder.addCase(fetchTenantDetails.fulfilled, (state, action) => {
      state.tenantsDetails = action.payload;
    });
    builder.addCase(fetchSequenceFormats.fulfilled, (state, action) => {
      state.sequenceFormats = action.payload?.sequenceFormats || [];
    });
    builder.addCase(fetchContactSequenceFormats.fulfilled, (state, action) => {
      state.contactSequenceFormats = action.payload?.sequenceFormats || [];
    });
    builder.addCase(fetchAccountSequenceFormats.fulfilled, (state, action) => {
      state.accountSequenceFormats = action.payload?.sequenceFormats || [];
    });
    builder.addCase(fetchDealSequenceFormats.fulfilled, (state, action) => {
      state.dealSequenceFormats = action.payload?.sequenceFormats || [];
    });
    builder.addCase(fetchActivitySequenceFormats.fulfilled, (state, action) => {
      state.activitySequenceFormats = action.payload?.sequenceFormats || [];
    });
    builder.addCase(fetchCustomPrintTemplates.fulfilled, (state, action) => {
      state.customPrintTemplates.list = action.payload;
    });
    builder.addCase(fetchTemplatePrintInfo.fulfilled, (state, action) => {
      state.templatePrintInfo = action.payload;
    });
    builder.addCase(fetchBooksInvoices.fulfilled, (state, action) => {
      state.documentData = action.payload;
    });
    builder.addCase(
      fetchBookDocumentsByContactIds.fulfilled,
      (state, action) => {
        state.documentData = action.payload;
      }
    );
    builder.addCase(fetchBooksInvoiceSummary.fulfilled, (state, action) => {
      state.invoiceSummary = action.payload;
    });
    builder.addCase(fetchBooksApprovalFlows.fulfilled, (state, action) => {
      state.approvalFlows = action.payload;
    });
    builder.addCase(fetchProductCustomFields.fulfilled, (state, action) => {
      state.productCustomFields = action.payload;
    });
    builder.addCase(fetchUOMs.fulfilled, (state, action) => {
      state.uoms = action.payload;
    });
    builder.addCase(fetchQuoteCustomFields.fulfilled, (state, action) => {
      state.quoteCustomFields = action.payload;
    });
    builder.addCase(fetchAllAdditionalCharge.fulfilled, (state, action) => {
      state.additionalCharges = action.payload;
    });
  }
});
export const {
  setSelectedPrintTemplate,
  setCustomPrintTemplates,
  setTemplatePrintInfo,
  setTenantFinanceDetails,
  setQuotes
} = booksSlice.actions;
export const productSelector = (state) => state.books.products;
export const productByPriceBookIdSelector = (state) =>
  state.books.productsByPriceBookId;
export const taxSelector = (state) => state.books.taxes;
export const getQuotes = (state) => state.books.quotes;
export const getTaxAccount = (state) => state.books.taxAccount;
export const getTenantsInfo = (state) => state.books.tenantsInfo;
export const getTenantsDetails = (state) => state.books.tenantsDetails;
export const getSequenceFormats = (state) => state.books.sequenceFormats;
export const getContactSequenceFormats = (state) =>
  state.books.contactSequenceFormats;
export const getDealSequenceFormats = (state) =>
  state.books.dealSequenceFormats;
export const getAccountSequenceFormats = (state) =>
  state.books.accountSequenceFormats;
export const getActivitySequenceFormats = (state) =>
  state.books.activitySequenceFormats;
export const getCustomPrintTemplates = (state) =>
  state.books.customPrintTemplates.list;
export const getSelectedPrintTemplate = (state) =>
  state.books.customPrintTemplates.selected;
export const selectTemplatePrintInfo = (state) => state.books.templatePrintInfo;
export const getDocumentData = (state) => state.books.documentData;
export const selectUOMs = (state) => state.books.uoms;
export const selectProductCustomFields = (state) =>
  state.books.productCustomFields;
export const selectQuoteCustomFields = (state) => state.books.quoteCustomFields;
export const selectAdditionalCharges = (state) => state.books.additionalCharges;
export const selectAdditionalSellCharges = (state) =>
  state.books.additionalCharges.filter(
    (sellSideCharge: any) =>
      sellSideCharge.applyTo === "SELL" || sellSideCharge.applyTo === "BOTH"
  );
export default booksSlice.reducer;
